@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
/* ======== GLOBAL GES'S STYLES ======== */
:root {
    --red: #ff0029;
    --pink: #ff4287;
    --pink-hover: #f3075f;
    --purple: #91217f;
    --purple-hover: #771a85;
    --orange: #ff653b;
    --orange-hover: #ff5323;
    --black: #000000;
    --white: #ffffff;
    --white-bg: #f9f9f9;
    --grey-0: #e4e4e4;
    --grey-1: #d3d3d3;
    --grey-2: #b8bdb6; /* inputs */
    --grey-3: #c2c2c2;
    --grey-4: #a9a9a9;
    --grey-5: #666666;
    --grey-6: #535353;
    --grey-table-1: #e7e7e7;
    --grey-table-1-dark: #b5b5b5;
    --grey-table-2: #f4f4f4;
    --grey-table-2-dark: #d2d2d2;
    --degree: linear-gradient(120deg, #ff2e7a 0%, #670775 100%);
}

html,
body {
    overflow: auto !important;
    margin: 0;
    padding: 0;
    min-height: 100%;
    background: var(--white-bg);
    scroll-behavior: auto !important;
}
.on-enjoy .exam-container {
    padding-bottom: calc(100vh - 150px);
}
  
@media (max-width: 1370px) {
    body.on-enjoy {
        zoom: 75%;
    }
    .enjoy_hint_label label, .enjoy_hint_label label li, .enjoy_hint_label div {
        font-size: 18px;
    }
}
@media (max-height: 860px) {
    body.on-enjoy {
        zoom: 75%;
    }
    .enjoy_hint_label label, .enjoy_hint_label label li, .enjoy_hint_label div {
        font-size: 18px;
    }
}

* {
    font-family: 'FiraSans', sans-serif;
    color: var(--grey-6);
}

*:focus {
    outline: none;
    box-shadow: none;
}

.hidden {
    visibility: hidden;
    width: 0;
    margin: 0 auto;
}

.btn.focus,
.btn:focus,
button:focus {
    outline: none;
    box-shadow: none;
}

.form-control.focus,
.form-control:focus,
button:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

div {
    box-sizing: border-box;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.question_content ul,
.answer_content ul {
  list-style: disc;
  padding: 0px 0px 0px 40px;
  margin: 0px 0px 1em 0px;
}
.question_content tbody,
.question_content td,
.question_content tfoot,
.question_content th,
.question_content thead,
.question_content tr,
.answer_content tbody,
.answer_content td,
.answer_content tfoot,
.answer_content th,
.answer_content thead,
.answer_content tr {
    border-width: 1px;
    border-color: black;
}

button[disabled] {
    opacity: .6;
    cursor: not-allowed !important;
}

a:hover {
    text-decoration: none;
}

span.plan sup {
    color: var(--white);
}

.text-right {
    text-align: right;
}

.ml-1 {
    margin-left: 5px;
}
.ml-2 {
    margin-left: 10px;
}
.ml-3 {
    margin-left: 15px;
}
.ml-auto {
    margin-left: auto;
}
.mr-1 {
    margin-right: 5px;
}
.mr-2 {
    margin-right: 10px;
}
.mr-3 {
    margin-right: 15px;
}
.mr-auto {
    margin-right: auto;
}
.mt-1 {
    margin-top: 5px;
}
.mt-2 {
    margin-top: 10px;
}
.mt-3 {
    margin-top: 15px;
}
.mt-auto {
    margin-top: auto;
}
.mb-1 {
    margin-bottom: 5px;
}
.mb-2 {
    margin-bottom: 10px;
}
.mb-3 {
    margin-bottom: 15px;
}
.mb-auto {
    margin-bottom: auto;
}
.mx-1 {
    margin-left: 5px;
    margin-right: 5px;
}
.mx-2 {
    margin-left: 10px;
    margin-right: 10px;    
}
.mx-3 {    
    margin-left: 15px;
    margin-right: 15px;    
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.pl-1 {
    padding-left: 5px;
}
.pl-2 {
    padding-left: 10px;
}
.pl-3 {
    padding-left: 15px;
}
.pl-auto {
    padding-left: auto;
}
.pr-1 {
    padding-right: 5px;
}
.pr-2 {
    padding-right: 10px;
}
.pr-3 {
    padding-right: 15px;
}
.pr-auto {
    padding-right: auto;
}
.pt-1 {
    padding-top: 5px;
}
.pt-2 {
    padding-top: 10px;
}
.pt-3 {
    padding-top: 15px;
}
.pt-auto {
    padding-top: auto;
}
.pb-1 {
    padding-bottom: 5px;
}
.pb-2 {
    padding-bottom: 10px;
}
.pb-3 {
    padding-bottom: 15px;
}
.px-1 {
    padding-left: 5px;
    padding-right: 5px;
}
.px-2 {
    padding-left: 10px;
    padding-right: 10px;
}
.px-3 {    
    padding-left: 15px;
    padding-right: 15px;
}

/* ==== COMPONENT STYLES ===== */

.main-content {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    height: 100%;
    padding: 40px 20px 40px 20px;

}
.dropdown button sup {
    color: var(--pink);
}

/* ======== CUSTOM CLASSES ==========*/

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-MediumItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    font-display: swap;
    src: url('./assets/fonts/FiraSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

/* ===== Title Section ===== */

.title-container {
    margin-bottom: 30px;
    border-bottom: 4px solid var(--grey-6);
}

.title-container h2 {
    font-size: 36px;
    font-weight: 700;
    color: var(--grey-6);
    margin-bottom: 20px;
    line-height: 1;
}

.input_container.search_box {
    display: inline-block;
    width: 100%;
    max-width: 100%;
}

.input_container.search_box input {
    padding-right: 35px;
}

.input_container.search_box .icon {
    filter: invert(1);
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 2px solid var(--grey-6);
    opacity: 1;
}

hr.bold {
    border-top: 4px solid var(--grey-6);
}


/* ====== Buttons ====== */

button,
.btn-class {
    width: 100%;
    height: 42px;
    line-height: 42px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 6px;
    background: var(--white-bg);
    transition: 0.6s;
    color: var(--grey-6);
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.btn_orange {
    background: var(--orange);
    color: var(--white);
}

.btn_orange:hover {
    background: var(--orange-hover);
    color: var(--white);
}

.btn_pink {
    background: var(--pink);
    color: var(--white);
}

.btn_pink:hover {
    background: var(--pink-hover);
    color: var(--white);
}

.btn_purple {
    background: var(--purple);
    color: var(--white);
}

.btn_purple:hover {
    background: var(--purple-hover);
    color: var(--white);
}

.btn_grey {
    background: var(--grey-4);
    color: var(--white);
}

.btn_grey:hover {
    background: var(--grey-5);
    color: var(--white);
}

.btn_new,
.btn_export {
    position: relative;
    text-align: start;
    padding: 0px 40px 0px 18px;
}
.btn_new::after,
.btn_export::after  {
    content: "";
    background-image: url("./assets/icons/plus_icon.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 10px);
    right: 14px;
}
.btn_export::after {
    background-image: url("./assets/icons/download_icon.png");
}
.btn_back {
    position: relative;
    text-align: end;
    padding: 0px 18px 0px 40px;
}
.btn_back::after {
    content: "";
    background-image: url("./assets/icons/caret_left.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 10px);
    left: 14px;
}

button.small {
    width: 85px;
    height: 26px;
    line-height: 26px;
    border-radius: 100px;
    font-weight: 600;
    font-size: 16px;
    border: none;
}

button.btn_purple.small {
    background: var(--purple);
    color: var(--white);
}

button.btn_purple.small:hover {
    background: var(--purple-hover);
}

button.btn_orange.small {
    background: var(--orange);
    color: var(--white);
}

button.btn_orange.small:hover {
    background: var(--orange-hover);
}

button.btn_pink.small {
    background: var(--pink);
    color: var(--white);
}

button.btn_pink.small:hover {
    background: var(--pink-hover);
}

.btn_purple i,
.btn_orange i,
.btn_pink i {
    color: var(--white);
}

.round_btn {
    display: inline-block;
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    padding: 0px;
    line-height: 40px;
    background: none;
}

button img.icon-print {
    filter: brightness(0) invert(1);
}

/* ====== Dropdowns ====== */

.dropdown {
    position: relative;
    width: 100%;
}

.dropdown.disabled {
    opacity: 0.8;
    pointer-events: none;
}

.dropdown button.dropdown_btn {
    text-align: left;
    font-size: 18px;
    padding: 0px 40px 0px 18px;
    background: var(--orange);
    border: none;
    color: var(--white) !important;
}

.dropdown button.dropdown_btn span {
    color: var(--white) !important;
}

.dropdown .btn_icon {
    position: absolute;
    top: 6px;
    right: 18px;
    font-size: 20px;
    cursor: pointer;
}

.dropdown .btn_icon img {
    width: 14px;
    cursor: pointer;
}

.dropdown .dropdown_menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 300px;
    background: var(--white);
    border-radius: 6px;
    z-index: 9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, .1);
    overflow-y: auto;
    text-align: left;
}

.dropdown .search_bar {
    padding: 14px 10px 8px;
    width: 100%;
    border-bottom: 1px solid var(--white-bg);
}

.dropdown li.filter-item {
    padding: 8px 10px;
    background: var(--white);
    border: none;
    border-bottom: 1px solid var(--white-bg);
    transition: 0.6s;
}

.dropdown li.filter-item.active {
    background: var(--white-bg);
}

.dropdown li.filter-item .index_counter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: auto;
    min-width: 24px;
    border-radius: 16px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    padding: 0px 4px;
    margin-right: 14px;
}

.dropdown li.filter-item .index_counter span {
    color: var(--white);
}

.dropdown li.filter-item span {
    display: inline;
    vertical-align: middle;
    line-height: 24px;
}


/* ====== Inputs ====== */

.input_container {
    position: relative;
    width: 100%;
}

.input_container input,
input:-internal-autofill-selected,
.input_container input:-internal-autofill-selected {
    width: 100%;
    height: 42px;
    font-size: 18px;
    font-weight: 400;
    border: none;
    padding: 0px 18px;
    border-radius: 6px;
    background-color: var(--grey-2) !important;
    color: var(--white) !important;
}

.input_container input::placeholder {
    padding: 0px !important;
    color: var(--white) !important;
}

.input_container .input_icon {
    position: absolute;
    top: 4px;
    right: 12px;
    font-size: 20px;
}

.input_container.datepicker {
    display: inline-block;
    width: 110px;
}
.input_container.datepicker input {
    height: auto;
    background-color: transparent !important;
    color: var(--grey-6) !important;
    padding: 0px 5px;
}
.input_container.datepicker input::placeholder {
    color: var(--grey-6) !important;
}

/* ===== Other Elements and Classess ===== */
.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 24px;
    background-color: var(--grey-1);
    border-radius: 12px;
    border: none;
    margin-left: 10px;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 4px;
    bottom: 0;
    margin: auto;
    background-color: var(--pink);
    border-radius: 50px;
    -webkit-transition: .4s;
    transition: .4s;
    width: 16px;
    height: 16px;
}
.slider.active {
    right: 4px;
    left: auto;
}
label.label-switch {
    color: var(--grey-6);
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
}

.index_counter {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    text-align: center;
    background: var(--orange);
}

.pointer {
    cursor: pointer !important;
}

.loader {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    justify-items: center;
    align-items: center;
    align-content: center;
    background-color: rgba(255, 255, 255, 0.303);
}

.loader img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 50px;
    width: 50px;
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.cdk-drag {
    cursor: move;
}

.cdk-drag-preview {
    visibility: hidden;
    opacity: 0;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.vertical {
    display: flex;
    justify-content: center;
    justify-items: center;
    /* flex-direction: column; */
}

hr.separator {
    width: 100%;
    margin: 8px 0px;
    border-top: 2px solid var(--white-bg);
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filters-container .dropdown,
.filters-container .btn_pink,
.filters-container .input_container,
.filters-container .pattern_item {
    margin: 5px 0px;
}

/* ===== Tables ===== */

.table-container {
    margin-bottom: 30px;
}
.table-container .buttons-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
}
.table-container button {
    margin-left: 8px;
}
.table_list {
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.list_item {
    background: var(--grey-table-1);
    margin-top: 2px;
    border-left: 10px solid var(--grey-table-1-dark);
    padding: 10px 15px;
    width: 100%;
    height: auto;
    transition: 0.6s;
}
.list_item.inter {
    background: var(--grey-table-2);
    border-left: 10px solid var(--grey-table-2-dark);
}
.list_item:hover {
    filter: contrast(1.1);
}
.list_item .index_counter {
    display: inline-block;
    vertical-align: middle;
}
.list_item p.title {
    display: inline-block;
    width: calc(100% - 100px);
    margin-left: 12px;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 400;
    color: var(--grey-6);
    vertical-align: middle;
}
.list_item button.edit_btn {
    margin-right: 8px;
}
.list_item.selected {
    background: var(--white-bg);
}

@media (max-width: 1035px) {
    .table-container .buttons-container {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
    }
    .table-container button {
        margin: 3px 0px;
    }
}

/* ===== Quantity shown Block ===== */

.quantity-block .quantity {
    margin-bottom: 20px;
}

.quantity-block .quantity span {
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
    color: var(--grey-6);
}


/* ===== Pagination ===== */

.pagination {
    margin: 14px 0px;
    float: right;
}

.pagination .page-link {
    margin: 0px;
    padding: 0px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.pagination .pagination-prev,
.pagination .pagination-prev .page-link,
.pagination .pagination-first,
.pagination .pagination-first .page-link,
.pagination .pagination-next,
.pagination .pagination-next .page-link,
.pagination .pagination-last,
.pagination .pagination-last .page-link {
    color: var(--white);
    font-size: 32px;
    font-weight: 500;
    border-radius: 24px;
    line-height: 30px;
    background: var(--orange);
    border: 1px solid var(--orange);
}

.pagination .pagination-prev,
.pagination .pagination-first {
    margin-right: 10px;
}

.pagination .pagination-next,
.pagination .pagination-last {
    margin-left: 10px;
}

.pagination .page-item.disabled {
    opacity: 0.6;
    display: none;
}

.page-item .page-link {
    color: var(--grey-4);
    background: var(--white-bg);
    border: none;
}

.page-item.active .page-link {
    color: var(--pink);
    background: var(--white-bg);
}


/* ===== TinyMCE ===== */

.mce-notification-inner,
.mce-notification {
  display:none!important;
}
.editor_wrap {
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--white-bg);
  background: var(--white-bg);
}
.editor_wrap .mce-panel,
.editor_wrap .tox-tinymce {
  border: none;
  box-shadow: none;
}
.editor_wrap div.mce-edit-area {
  height: 300px;
}
.editor_wrap .mce-statusbar,
.tox .tox-statusbar,
.tox .tox-statusbar__branding,
.tox .tox-promotion {
    display: none;
    visibility: hidden;
    opacity: 0;
}
.editor_wrap .tox .tox-edit-area::before {
    border-color: var(--pink);
}
.wrs_modal_dialogContainer button {
    line-height: 1;
}
.wrs_tickContainer {
    display: none;
}

/* ====== ANIMATIONS ======== */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}


/* ======== CONFIRM BOX =========== */

.confirm-overlay {
    position: fixed;
    background: rgba(0, 0, 0, .2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.confirm-box {
    position: absolute;
    background: var(--white-bg);
    padding: 20px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, .2);
    height: 246px;
    border-radius: 14px;
    max-width: 90%;
    width: 490px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.errorValidation {
    font-size: 13px;
    font-weight: 500;
    padding-left: 10px;
    color: var(--red);
}

input.invalid,
.input_container input.invalid,
button.dropdown_btn.invalid {
    transition: 0.6s;
    border: 2px solid var(--red);
}


/* ======== DATE PICKERS ========== */

.bs-datepicker {
    box-shadow: 0 0 10px 0 rgba(170, 170, 170, 0.431);
    border-radius: 20px;
}

.bs-datepicker *,
.bs-datepicker *::after,
.bs-datepicker *::before {
    font-family: 'Signika', sans-serif;
}

.bs-datepicker button,
.bs-datepicker .btn-class {
    font-size: 14px;
    width: auto;
}

.bs-datepicker-body {
    border: none;
}

.bs-datepicker-head * {
    color: var(--white);
}

.theme-blue .bs-datepicker-body table td span.selected,
.theme-blue .bs-datepicker-body table td span[class*=select-]:after,
.theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td[class*=select-] span:after,
.theme-blue .bs-datepicker-head {
    background-color: var(--purple);
    border-radius: 36px;
}

@media screen and (max-width: 920px) {
    .bs-datepicker-multiple {
        display: flex;
    }
}

@media (max-width: 667px) {
    button.small {
        width: 100%;
        margin: 2px;
    }
}

/* ==== EnjoyHint wizard ==== */

.privacy a {
    color: var(--pink);
}
.privacy a:hover {
    text-decoration: underline;
}

/* ==== EnjoyHint wizard ==== */

.enjoyhint .enjoyhint_next_btn,
.enjoyhint .enjoyhint_skip_btn {
    border: none;
    border-radius: 6px;
    color: var(--white);
    background-color: var(--pink);
}

.enjoyhint .enjoyhint_next_btn:hover,
.enjoyhint .enjoyhint_skip_btn:hover {
    color: var(--white);
    background-color: var(--pink);
}

.enjoyhint .enjoyhint_close_btn {
    border: none;
    background-color: var(--pink);
    right: 20px !important;
    top: 19px !important;
    transition: transform .3s;
}

.enjoyhint .enjoyhint_close_btn:hover {
    transform: scale(1.1);
    background-color: var(--pink);
}

img.wizard-img {
    width: 80%;
    margin: 20px;
    border-radius: 10px;
}

.top-btn {
    top: auto !important;
    bottom: 50px;
}

@media screen and (max-width: 992px) {
    .enjoyhint .enjoyhint_close_btn {
        right: 22px !important;
        top: 14px !important;
    }
}


/* ===== Curriculums List ===== */
  
.curriculums_list .card.card-accordion {
    padding: 0px;
}

.curriculums_list .index_counter.add_curriculum:hover {
    background: var(--pink);
}

.curriculums_list .accordion_item,
.curriculums_list .accordion_item * {
    max-width: 100%;
}

.curriculums_list .btn.btn-link,
.curriculums_list .btn.btn-link:hover {
    text-decoration: none;
    color: inherit;
    text-align: left;
    padding-right: 30px;
    width: 100%;
    white-space: normal !important;
    display: block !important;
    position: relative;
}

.curriculums_list .btn.btn-link::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    right: 5px;
    background-image: url('assets/icons/caret_down.png');
    filter: invert(1);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotateX(180deg);
    transition: transform .5s linear;
}

.curriculums_list .btn.btn-link.collapsed::after {
    transform: rotateX(0deg);
}